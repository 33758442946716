@import "../../index.scss";

.container-control {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-height: 300px !important;
    background-color: $bgYellow;
}
.gallery {
    width: 100%;
    padding-top: 100px;

    @include media-breakpoint-down(sm) {
        padding-top: 24px;
    }
    .page-control {
        background: url(../../assets/images/detail/bg-detail.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        z-index: 0;
        background-position: center;
        height: 1500px;
        padding-bottom: 50px;
    }
    .text-header {
        z-index: 2;
        font-size: 112px;
        font-weight: 400;
        font-family: $NanumRegular;
        color: $brown;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 60px;
        }
    }

    .filter-control {
        display: grid;
        grid-template-columns: 20% 40% 40%;
        padding-left: 15%;
        padding-right: 15%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(xl) {
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            grid-template-columns: 100%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            grid-template-columns: 100%;
        }
    }

    .text-filter {
        z-index: 2;
        font-size: 33px;
        margin-top: auto;
        margin-bottom: auto;
        color: $brown;
        font-family: $NunitoExtraBold;
        @include media-breakpoint-down(sm) {
            font-size: 24px;
        }
    }

    .chakra-select__icon-wrapper {
        @include media-breakpoint-down(md) {
            right: 20% !important;
            margin-top: 8px;
        }

        @include media-breakpoint-down(sm) {
            right: 5% !important;
            margin-top: 8px;
        }
    }

    .text-trick {
        z-index: 2;
        font-size: 18px;
        font-family: $NanumRegular;
        margin-top: 16px;
        text-align: left;
        padding-left: 16%;
        @include media-breakpoint-down(xl) {
            padding-left: 7%;
        }
        @include media-breakpoint-down(lg) {
            padding-left: 7%;
        }
        @include media-breakpoint-down(md) {
            padding-left: 7%;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 5%;
        }
    }

    .no-result-text {
        z-index: 5;
        font-size: 46px;
        font-family: $NanumRegular;
        font-weight: 700;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 100px;
    }
    .dropdown-img {
        width: 20px;
        height: 20px;

        @include media-breakpoint-down(md) {
            width: 17px;
            height: 17px;
            right: 20%;
        }
    }
    .border-dropdown {
        cursor: pointer;
        position: relative;
        width: 90% !important;
        height: 56px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: auto;
        margin-right: auto;
        background: #f9f5eb;
        border: 4px solid $brown;
        box-shadow: 8px 8px 0px $brown;
        border-radius: 16px;
        font-size: 20px;
        font-family: $NunitoExtraBold;
        color: $brown;
        text-align: center;

        @include media-breakpoint-down(lg) {
            font-size: 16px;
        }
        @include media-breakpoint-down(md) {
            font-size: 14px;
            width: 70% !important;
            margin-top: 16px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 18px;
            width: 100% !important;
        }
        &:hover {
            border: 4px solid $brown;
        }
    }
    .grid-control {
        background-color: $softYellow;
        z-index: 2;
        width: 100%;
        padding-left: 15%;
        padding-right: 15%;
        display: grid;
        padding-bottom: 100px;
        grid-template-columns: 25% 25% 25% 25%;
        @include media-breakpoint-down(xl) {
            padding-left: 5%;
            padding-right: 5%;
        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: 33% 33% 33%;
            padding-left: 5%;
            padding-right: 5%;
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: 50% 50%;
            padding-left: 5%;
            padding-right: 5%;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 2%;
            padding-right: 2%;
        }

        .border-item {
            display: flex;
            margin-top: 16px;
            width: 90%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: $bgMain;
            border-radius: 16px;
            border: solid 4px $brown;
            box-shadow: 4px 4px 0px $brown;

            @include media-breakpoint-down(xl) {
                padding-left: 8px;
                padding-right: 8px;
            }
        }

        .item-img-control{
            display: inline-block;
            overflow: hidden;
            border: 5px solid $brown;
            border-radius: 16px;
            .item-img {
                width: 100%;
                height: auto;
                object-fit: cover;
                margin-left: auto;
                margin-right: auto;
               
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -ms-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
    
                vertical-align: middle;
    
                &:hover {
                    -webkit-transform: scale(1.2); /* Safari and Chrome */
                    -moz-transform: scale(1.2); /* Firefox */
                    -ms-transform: scale(1.2); /* IE 9 */
                    -o-transform: scale(1.2); /* Opera */
                    transform: scale(1.2);
                    
                }
            }
        }

       

        .item-id {
            font-size: 22px;
            font-family: $NunitoExtraBold;
            text-align: left;
            margin-top: 16px;
            color: $brown;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }
    }

    .button-control {
        width: 100%;
        background-color: $softYellow;
        padding-bottom: 40px;
    }

    .level-border {
        border-radius: 16px;
        padding-left: 8px;
        padding-right: 8px;
        height: 41px;
        font-size: 16px;
        font-family: $NunitoExtraBold;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    .img-level {
        width: auto;
        height: 24px;
        margin-top: auto;
        margin-bottom: auto;
        object-fit: contain;
        @include media-breakpoint-down(sm) {
            height: 16px;
        }
    }

    .icon-level {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-down(sm) {
            width: 14px;
            height: 14px;
        }
    }

    .text-level {
        font-size: 18px;
        margin-top: auto;
        margin-bottom: auto;
        font-family: $fontBold;
        font-weight: 700;
        margin-left: 6px;
        margin-right: 6px;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .button-loadmore {
        margin-top: 25px;
        width: 300px;
        height: 61px;
        color: $brown;
        font-family: $NanumRegular;
        font-size: 28px;
        padding-left: 24px;
        padding-right: 24px;
        font-weight: 700;
        border-radius: 16px;
        background-color: $bgMain;
        border: 4px solid $brown;
        box-shadow: 6px 6px 0px $brown;
    }

    .button-buynow {
        cursor: pointer;
        margin-top: 40px !important;
        width: 100%;
        height: 51px;
        color: white;
        font-family: $NanumRegular;
        font-size: 28px;
        padding-left: 24px;
        padding-right: 24px;
        font-weight: 700;
        border-radius: 16px;
        background-color: $brown;
        @include media-breakpoint-down(md){
            font-size: 19px;
        }
    }
    .button-more-detail {
        cursor: pointer;
        margin-top: 8px !important;
        width: 100%;
        height: 51px;
        color: $brown;
        font-family: $NanumRegular;
        font-size: 28px;
        padding-left: 24px;
        padding-right: 24px;
        font-weight: 700;
        border-radius: 16px;
        background-color: $bgMain;
        border: 4px solid $brown;

        @include media-breakpoint-down(md){
            font-size: 19px;
        }
    }
}

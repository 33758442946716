@import "../index.scss";

.get-card {
    .border-control {
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 16px !important;
        background-color: #f9f5eb;
        box-shadow: 8px 5px 0px $brown !important;
        border-style: solid !important;
        border-width: 4px !important;
        padding-top: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 70px !important;
        min-height: 550px !important;

        @include media-breakpoint-down(md) {
            min-height: 400px !important;
        }

        .bg-star {
            width: 100%;
            height: auto;
            object-fit: contain;
            position: absolute;
            top: 0;
            margin-top: -50px;
        }

        .card-control {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            width: 70%;

            .card-img {
                margin-top: -70px;
                width: 60%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
                animation: bounce 2s infinite;
            }

            .shadow-img {
                margin-top: -20px;
                width: 60%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
            }
        }

        .text-control {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            width: 70%;

            .text-message {
                font-size: 40px;
                font-family: $NunitoExtraBold;
                color: $brown;
                text-align: center;
            }

            .button-ok {
                box-sizing: border-box;
                width: auto;
                margin-top: 35px;
                font-size: 40px !important;
                min-height: 61px !important;
                padding-left: 48px !important;
                padding-right: 48px !important;
                color: white;
                font-family: $NanumRegular;
                font-weight: 400;
                background: $brown;
                border: 3px solid #ffffff;
                box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
                border-radius: 8px;
        
                @include media-breakpoint-down(md) {
                    min-width: 200px !important;
                }
            }
        }

        .img-desktop {
            width: 70%;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .lottie-control {
        width: 200px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
        margin-top: 50px;
    }
    .loading-control {
        width: 100%;
        height: auto;
        margin: auto;
        .button-default {
            margin-top: 30px;
            margin-bottom: 30px !important;
            min-width: 310px !important;
            height: 51px;
            font-size: 18px;
            border-style: none;
            font-weight: 700;
            font-family: $fontMedium;
            color: white;
            padding-top: 6px;
            padding-bottom: 6px;
            width: 410px;
            background: var(--red);
            border: 3px solid white;
            box-sizing: border-box;
            box-shadow: 4px 6px 0px white;
            border-radius: 8px;
            animation: bounce 2s infinite;
            @include media-breakpoint-down(md) {
                width: 70% !important;
                font-size: 19px;
                padding-top: 8px;
                padding-bottom: 8px;
            }

            @include media-breakpoint-down(sm) {
                width: 100% !important;
                min-height: auto !important;
                min-width: 0 !important;
                padding: 8px !important;
                height: auto;
            }
        }
        .text-loading {
            color: white;
            font-size: 22px;
            margin-top: 25px;
            font-family: $fontBold;
            text-align: center;
            animation: blink 1s infinite;
        }

        .text-desc {
            color: white;
            font-size: 17px;
            margin-top: 25px;
            font-family: $fontRegular;
            text-align: center;
        }

        @keyframes blink {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .close-button {
        z-index: 4 !important;
    }
    .background-control {
        border: none !important;
        background-color: rgba($color: var(--dark), $alpha: 1) !important;
        margin-top: 100px !important;
        width: 30% !important;

        @include media-breakpoint-down(lg) {
            width: 50% !important;
        }
        @include media-breakpoint-down(md) {
            margin-top: 100px !important;
            width: 60% !important;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 100px !important;
            width: 80% !important;
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -o-transform: translateY(0);
        }

        50% {
            transform: translateY(-10px);
            -webkit-transform: translateY(-10px);
            -o-transform: translateY(-10px);
        }

        100% {
            transform: translateY(0);
            -webkit-transform: translateY(0);
            -o-transform: translateY(0);
        }
    }
}

@import "../../index.scss";
:root {
    --color1: rgb(253, 253, 253);
    --color2: rgb(255, 166, 0);
    --back: url(https://cdn2.bulbagarden.net/upload/1/17/Cardback.jpg);
    --charizard1: #fac;
    --charizard2: #ddccaa;
    --charizardfront: url(https://assets.codepen.io/13471/charizard-gx.webp);
    --pika1: #fff;
    --pika2: #fff;
    --pikafront: url(https://assets.codepen.io/13471/pikachu-gx.webp);
    --eevee1: #efb2fb;
    --eevee2: #acc6f8;
    --eeveefront: url(https://assets.codepen.io/13471/eevee-gx.webp);
    --mewtwo1: #efb2fb;
    --mewtwo2: #acc6f8;
    --mewtwofront: url(https://assets.codepen.io/13471/mewtwo-gx.webp);
    --moon: url(https://firebasestorage.googleapis.com/v0/b/monday-club-48189.appspot.com/o/cards%2Fmoon.png?alt=media);
    --metal: url(https://firebasestorage.googleapis.com/v0/b/monday-club-48189.appspot.com/o/cards%2Fmetal.png?alt=media);
    --wood: url(https://firebasestorage.googleapis.com/v0/b/monday-club-48189.appspot.com/o/cards%2Fwood.png?alt=media);
    --earth: url(https://firebasestorage.googleapis.com/v0/b/monday-club-48189.appspot.com/o/cards%2Fearth.png?alt=media);
    --fire: url(https://firebasestorage.googleapis.com/v0/b/monday-club-48189.appspot.com/o/cards%2Ffire.png?alt=media);
    --sun: url(https://firebasestorage.googleapis.com/v0/b/monday-club-48189.appspot.com/o/cards%2Fsun.png?alt=media);
    --water: url(https://firebasestorage.googleapis.com/v0/b/monday-club-48189.appspot.com/o/cards%2Fwater.png?alt=media);
}

.card {
    width: 250px !important;
    height: 370px !important;
    // width: clamp(200px, 61vh, 18vw);
    // height: clamp(280px, 85vh, 25.2vw);
    @media screen and (min-width: 600px) {
        // width: 61vh;
        // height: 85vh;
        // max-width: 500px;
        // max-height: 700px;
        width: clamp(12.9vw, 61vh, 18vw);
        height: clamp(18vw, 85vh, 25.2vw);
    }

    position: relative;
    overflow: hidden;
    overflow: hidden;
    z-index: 99999;
    touch-action: none;

    border-radius: 5% / 3.5%;
    box-shadow: -5px -5px 5px -5px var(--color1), 5px 5px 5px -5px var(--color2), -7px -7px 10px -5px transparent,
        7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5);

    transition: transform 0.5s ease, box-shadow 0.2s ease;
    will-change: transform, filter;

    background-color: transparent;
    background-image: var(--front);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transform-origin: center;

    @include media-breakpoint-down(md){
        width: 200px !important;
        height: 320px !important;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.card:hover {
    box-shadow: -20px -20px 30px -25px var(--color1), 20px 20px 30px -25px var(--color2),
        -7px -7px 10px -5px var(--color1), 7px 7px 10px -5px var(--color2), 0 0 13px 4px rgba(255, 255, 255, 0.3),
        0 55px 35px -20px rgba(0, 0, 0, 0.5);
}

.card.charizard {
    --color1: var(--charizard1);
    --color2: var(--charizard2);
    --front: var(--charizardfront);
}
.card.wood {
    position: absolute;
 
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--wood);
}
.card.earth {
    position: absolute;
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--earth);
}
.card.fire {
    position: absolute;
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--fire);
}
.card.sun {
    position: absolute;
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--sun);
}
.card.water {
    position: absolute;
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--water);
}
.card.moon {
    position: absolute;
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--moon);
}
.card.metal {
    position: absolute;
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--metal);
}
.card.pika {
    --color1: var(--pika1);
    --color2: var(--pika2);
    --front: var(--pikafront);
}
.card.mewtwo {
    --color1: var(--mewtwo1);
    --color2: var(--mewtwo2);
    --front: var(--mewtwofront);
}
.card.eevee {
    --color1: #ec9bb6;
    --color2: #ccac6f;
    --color3: #69e4a5;
    --color4: #8ec5d6;
    --color5: #b98cce;
    --front: var(--eeveefront);
}

.card:before,
.card:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    opacity: 0.5;
    mix-blend-mode: color-dodge;
    transition: all 0.33s ease;
}

.card:before {
    background-position: 50% 50%;
    background-size: 300% 300%;
    background-image: linear-gradient(
        115deg,
        transparent 0%,
        var(--color1) 25%,
        transparent 47%,
        transparent 53%,
        var(--color2) 75%,
        transparent 100%
    );
    opacity: 0.5;
    filter: brightness(0.5) contrast(1);
    z-index: 1;
}

.card:after {
    opacity: 1;
    // background-image: url("https://assets.codepen.io/13471/sparkles.gif"),
    //   url(https://assets.codepen.io/13471/holo.png),
    //   linear-gradient(125deg, #ff008450 15%, #fca40040 30%, #ffff0030 40%, #00ff8a20 60%, #00cfff40 70%, #cc4cfa50 85%);
    // background-position: 50% 50%;
    // background-size: 160%;
    // background-blend-mode: overlay;
    // z-index: 2;
    // filter: brightness(1) contrast(1);
    // transition: all .33s ease;
    // mix-blend-mode: color-dodge;
    // opacity: .75;
}

.card.active:after,
.card:hover:after {
    filter: brightness(1) contrast(1);
    opacity: 1;
}

.card.active,
.card:hover {
    animation: none;
    transition: box-shadow 0.1s ease-out;
}

.card.active:before,
.card:hover:before {
    animation: none;
    background-image: linear-gradient(90deg, transparent 25%, var(--color1) 48%, var(--color2) 52%, transparent 75%);
    background-position: 50% 50%;
    background-size: 100% 100%;
    opacity: 1;
    filter: brightness(1) contrast(1);
    transition: none;
}

.card.active:before,
.card:hover:before,
.card.active:after,
.card:hover:after {
    animation: none;
    transition: none;
}

.card.animated {
    transition: none;
    animation: holoCard 12s ease 0s 1;
    &:before {
        transition: none;
        animation: holoGradient 12s ease 0s 1;
    }
    &:after {
        transition: none;
        animation: holoSparkle 12s ease 0s 1;
    }
}
.animated {
    transition: none;
    animation: holoCard 12s ease 0s 1;
    &:before {
        transition: none;
        animation: holoGradient 12s ease 0s 1;
    }
    &:after {
        transition: none;
        animation: holoSparkle 12s ease 0s 1;
    }
}

@keyframes holoSparkle {
    0%,
    100% {
        opacity: 0.75;
        background-position: 50% 50%;
        filter: brightness(1.2) contrast(1.25);
    }
    5%,
    8% {
        opacity: 1;
        background-position: 40% 40%;
        filter: brightness(0.8) contrast(1.2);
    }
    13%,
    16% {
        opacity: 0.5;
        background-position: 50% 50%;
        filter: brightness(1.2) contrast(0.8);
    }
    35%,
    38% {
        opacity: 1;
        background-position: 60% 60%;
        filter: brightness(1) contrast(1);
    }
    55% {
        opacity: 0.33;
        background-position: 45% 45%;
        filter: brightness(1.2) contrast(1.25);
    }
}

@keyframes holoGradient {
    0%,
    100% {
        opacity: 0.5;
        background-position: 50% 50%;
        filter: brightness(0.5) contrast(1);
    }
    5%,
    9% {
        background-position: 100% 100%;
        opacity: 1;
        filter: brightness(0.75) contrast(1.25);
    }
    13%,
    17% {
        background-position: 0% 0%;
        opacity: 0.88;
    }
    35%,
    39% {
        background-position: 100% 100%;
        opacity: 1;
        filter: brightness(0.5) contrast(1);
    }
    55% {
        background-position: 0% 0%;
        opacity: 1;
        filter: brightness(0.75) contrast(1.25);
    }
}

@keyframes holoCard {
    0%,
    100% {
        transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
    }
    5%,
    8% {
        transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
    }
    13%,
    16% {
        transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
    }
    35%,
    38% {
        transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
    }
    55% {
        transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
    }
}

.card.eevee:hover {
    box-shadow: 0 0 30px -5px white, 0 0 10px -2px white, 0 55px 35px -20px rgba(0, 0, 0, 0.5);
}
.card.eevee:hover:before,
.card.eevee.active:before {
    background-image: linear-gradient(
        115deg,
        transparent 20%,
        var(--color1) 36%,
        var(--color2) 43%,
        var(--color3) 50%,
        var(--color4) 57%,
        var(--color5) 64%,
        transparent 80%
    );
}

.demo .card {
    background-image: var(--back);
    font-size: 2vh;
}
.demo .card > span {
    position: relative;
    top: 45%;
}

.demo .card:nth-of-type(1),
.demo .card:nth-of-type(2),
.demo .card:nth-of-type(3) {
    width: 20vh;
    height: 27.5vh;
    box-shadow: inset 0 0 0 1px rgba(white, 0.4), 0 25px 15px -10px rgba(0, 0, 0, 0.5);
    animation: none;
}

.demo .card:nth-of-type(1),
.demo .card:nth-of-type(2),
.demo .card:nth-of-type(3) {
    &:before,
    &:after {
        animation: none;
        // opacity: 1;
    }
}
.demo .card:nth-of-type(1) {
    &:before,
    &:after {
        display: none;
    }
}
.demo .card:nth-of-type(2) {
    background: none;
    &:before {
        display: none;
    }
}
.demo .card:nth-of-type(3) {
    background: none;
    &:after {
        display: none;
    }
}

.operator {
    display: inline-block;
    vertical-align: middle;
    font-size: 6vh;
}

html,
body {
    height: 100%;
    background-color: #333844;
    padding: 0;
    z-index: 1;
    transform: translate3d(0, 0, 0.1px);
}
body {
    color: white;
    background-color: #333844;
    font-family: "Heebo", sans-serif;
    text-align: center;
}
h1 {
    display: block;
    margin: 30px 0;
}
p {
    margin-top: 5px;
    font-weight: 200;
}
#app {
    position: relative;
}

.demo,
.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    perspective: 2000px;
    z-index: 5;
    transform: translate3d(0.1px, 0.1px, 0.1px);
}
.demo {
    flex-direction: row;
    justify-content: center;
}

@media screen and (min-width: 576px) {
    .cards {
        flex-direction: row;
    }
}

.cards .card {
    &:nth-child(2) {
        &,
        &:before,
        &:after {
            animation-delay: 0.25s;
        }
    }
    &:nth-child(3) {
        &,
        &:before,
        &:after {
            animation-delay: 0.5s;
        }
    }
    &:nth-child(4) {
        &,
        &:before,
        &:after {
            animation-delay: 0.75s;
        }
    }
}

p {
    font-weight: 400;
    font-size: 18px;
    padding: 1em;
    background: rgba(0, 0, 0, 0.3);
    margin-top: 0;
}

p.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
}
p a {
    color: cyan;
}

html,
body,
main {
    min-height: 100%;
}

@import "../../index.scss";

.container-control {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-height: 300px !important;
    background-color: $bgYellow;
}

.img-cover-check-card{
    margin-top: 24px;
    width: 80%;
    height: auto;
    border-radius: 16px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
}


.img-card-control {
    cursor: pointer;
    width: 250px;
    position: relative;
    margin: 20px;
    min-height: 450px;

    .animate {
        transition: none;
        animation: holoCard 12s ease 0s 1;
        &:before {
            transition: none;
            animation: holoGradient 12s ease 0s 1;
        }
        &:after {
            transition: none;
            animation: holoSparkle 12s ease 0s 1;
        }
    }

    @include media-breakpoint-down(md){
    }
}

.loading-nft-control{
    display: flex;
    flex-direction: column;
    height: 300px !important;

    .text-loading{
        margin-top: auto;
        margin-bottom: auto;
        font-size: 34px;
        color: $brown !important;
        text-align: center;
        font-family: 'Nunito-Bold';
        animation: bounce 2s infinite;
    }
}

.no-nft-control{
    display: flex;
    flex-direction: column;

    .text-no-nft{
        font-size: 28px;
        color: $brown;
        text-align: center;
        font-family: 'Nunito-Bold';
    }

    .button-active {
        margin-top: 30px;
        width: 40%;
        padding: 4px 24px;
        background: $yellow;
        margin-left: auto;
        margin-right: auto;
        border: 3px solid $yellow;
        box-sizing: border-box;
        box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
        border-radius: 8px;
        font-family: $NanumRegular;
        color: white;
        font-size: 30px;
      
    }
}




.text-nft-in-card{
    position: absolute;
    margin-top: 268px;
    z-index: 99999;
    color: white;
    font-size: 17px;
    font-weight: bold;
    font-family: 'Nunito-Bold';
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md){
        margin-top: 121%;
    }

    @include media-breakpoint-down(sm){
        margin-top: 92%;
    }
}

.img-nft-in-card {
    position: absolute;
    width: 245px;
    margin-top: 40px;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 16px;
    z-index: 1;
    object-fit: cover;

    @include media-breakpoint-down(md){
        width: 200px !important;
        height: 200px !important;
    }
}

.button-active {
    margin-top: 30px;
    width: auto;
    min-width: 360px;
    padding: 4px 24px;
    background: $yellow;
    border: 3px solid $yellow;
    box-sizing: border-box;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    font-family: $NanumRegular;
    color: white;
    font-size: 48px;
    @include media-breakpoint-down(lg) {
        font-size: 30px;
        margin-top: 20px;
        min-width: 170px;
    }
    @include media-breakpoint-down(md) {
        font-size: 24px;
        margin-top: 20px;
        min-width: 180px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 20px;
        margin-top: 20px;
        min-width: 140px;
    }
}

.timeline-img {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: 30px;
    margin-bottom: 30px;
}

.button-active-red {
    margin-top: 30px;
    width: auto;
    min-width: 360px;
    padding: 4px 24px;
    background: $red;
    border: 3px solid $red;
    box-sizing: border-box;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    font-family: $NanumRegular;
    color: white;
    font-size: 48px;
    @include media-breakpoint-down(lg) {
        font-size: 30px;
        margin-top: 20px;
        min-width: 170px;
    }
    @include media-breakpoint-down(md) {
        font-size: 24px;
        margin-top: 20px;
        min-width: 180px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 20px;
        margin-top: 20px;
        min-width: 140px;
    }
}

.button-disable {
    margin-top: 30px;
    width: auto;
    min-width: 360px;
    padding: 4px 24px;
    background: #bcb8b4;
    border: 3px solid white;
    box-sizing: border-box;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    font-family: $NanumRegular;
    color: white;
    font-size: 48px;

    @include media-breakpoint-down(md) {
        font-size: 24px;
        margin-top: 20px;
        min-width: 180px;
    }
}

.section-1-control {
    .grid-button-control {
        display: grid;
        grid-template-columns: 45% 10% 45%;

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }

        .divider-yellow {
            width: 3px;
            height: 300px;
            border-right: dashed 4px $yellow;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .img-giveaway {
            width: 300px;
            height: 80px;
            object-fit: contain;

            @include media-breakpoint-down(md) {
                margin-top: 24px;
            }
        }

        .text-giveaway {
            font-size: 24px;
            color: $brown;
            font-family: $fontMedium;
        }

        .text-countdown-red {
            font-size: 30px;
            color: $red;
            font-family: $fontBold;
            text-align: center;
            margin-top: 25px;
        }
    }
    .text-brown-desc {
        font-size: 22px;
        margin-top: 20px;
        color: $brown;
        font-family: $NunitoExtraBold;
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: 17px;
            text-align: center;
        }
    }
    .question-img {
        width: 40px;
        height: auto;
        object-fit: contain;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
            width: 20px;
        }
    }
    .img-top {
        width: 100%;
        height: auto;
        margin-top: -80px;
        object-fit: contain;
    }

    .img-wording {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 80%;
        height: 200px;
        object-fit: contain;
        animation: bounce 2s infinite;

        @include media-breakpoint-down(md) {
            width: 100%;
            height: 80px;
            margin-top: 60px;
        }
    }

    .bottom-control {
        margin-top: 40px;
        width: 100%;
        position: relative;
        overflow-x: hidden;
        height: auto;

        .img-bottom {
            z-index: 1;
            position: absolute;
            width: 100%;
            height: auto;
            left: 0;
        }

        .img-nft-group {
            margin-top: -8%;
            z-index: 2;
            width: 100%;
            height: auto;
            object-fit: contain;
            margin-bottom: -5%;

            @include media-breakpoint-down(md) {
                margin-top: 0px;
            }
        }

        .img-bottom-2 {
            background: url(../../assets/images/bottom-1-2.png);
            margin-top: -8%;
            min-height: 500px;
            background-size: cover;
            background-position-x: center;
            background-repeat: no-repeat;
            z-index: 3;
            width: 100%;
            height: auto;
            @include media-breakpoint-down(xl) {
                background-size: cover;
                background-position-x: center;
                background-repeat: no-repeat;
            }
            @include media-breakpoint-down(md) {
                background: url(../../assets/images/bottom-1-2-mobile.png);
                background-size: cover;
                background-position-x: center;
                background-repeat: no-repeat;
                margin-top: -7%;
            }
        }

        .text-section-2-control {
            margin-top: 120px;
            @include media-breakpoint-down(lg) {
                margin-top: 80px;
            }
            @include media-breakpoint-down(md) {
                margin-top: 60px;
            }
        }

        .text-brown-header {
            color: $brown;
            font-size: 80px;
            font-family: $NunitoExtraBold;

            @include media-breakpoint-down(xl) {
                font-size: 60px;
                line-height: 1.2em;
                margin-top: 40px;
            }

            @include media-breakpoint-down(md) {
                font-size: 40px;
                margin-top: 40px;
            }

            @include media-breakpoint-down(md) {
                font-size: 30px;
                margin-top: 40px;
            }
        }

        .text-white-header {
            margin-top: 70px;
            color: white;
            font-size: 40px;
            font-family: $NunitoExtraBold;

            @include media-breakpoint-down(md) {
                font-size: 50px;
            }

            @include media-breakpoint-down(md) {
                font-size: 30px;
            }
        }

        .marquee-control {
            position: relative;
            width: 100vw;
            max-width: 100%;
            height: 200px;
            overflow-x: hidden;
            @include media-breakpoint-down(md) {
                height: 100px;
            }
        }

        .classify-control {
            position: absolute;
            white-space: nowrap;
            will-change: transform;
            font-size: 107px;
            font-family: $fontBold;
            margin-top: 20px;
            color: white;
            animation: marquee 4s linear infinite;
            @include media-breakpoint-down(md) {
                font-size: 40px;
            }
            .text-control {
                margin-left: 14px;
                margin-right: 14px;
            }
        }
    }
}

.click-here {
    cursor: pointer;
    font-size: 33px;
    font-family: $NanumRegular;
    color: #e34301;
    text-decoration: underline;
}

.section-2-control {
    background-color: $bgYellow;
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-down(md) {
        padding-left: 16px;
        padding-right: 16px;
    }
    .text-brown-desc {
        font-size: 22px;
        margin-top: 20px;
        color: $brown;
        font-family: $NunitoExtraBold;
        text-align: left;

        @include media-breakpoint-down(md) {
            font-size: 17px;
            text-align: center;
        }
    }
    .psn-gif {
        width: 80%;
        height: auto;
        object-fit: contain;

        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
            width: 100%;
        }
    }

    .text-brown-header {
        color: $brown;
        font-size: 56px;
        font-family: $NunitoExtraBold;

        @include media-breakpoint-down(md) {
            font-size: 40px;
            margin-top: 40px;
        }
    }

    .grid-bonus-control {
        margin-top: 60px;
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        @include media-breakpoint-down(lg) {
            grid-template-columns: 50% 50%;
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: 50% 50%;
        }

        .bonus-last-row {
            @include media-breakpoint-down(lg) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 3;
                grid-row-end: 3;
            }
            @include media-breakpoint-down(md) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 3;
                grid-row-end: 3;
            }
        }

        .bonus-img {
            width: 140px;
            height: auto;
            object-fit: contain;

            @include media-breakpoint-down(md) {
                margin-top: 16px;
                width: 70px;
            }
        }

        .text-bonus {
            width: 70%;
            margin-top: 16px;
            color: $brown;
            font-size: 24px;
            font-family: $NunitoBold;
            line-height: 1.5em;

            @include media-breakpoint-down(md) {
                width: 100%;
                font-size: 18px;
                line-height: 1.2em;
            }
        }
    }

    .grid-control {
        margin-top: 100px;
        display: grid;
        grid-template-columns: 50% 50%;
        @include media-breakpoint-down(lg) {
            grid-template-columns: 100%;
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }

        .grid-left-control {
            padding-right: 40px;

            @include media-breakpoint-down(md) {
                padding-right: 0px;
            }
        }
        .img-star {
            margin-bottom: 40px;
            width: 77px;
            height: auto;
            object-fit: contain;
            @include media-breakpoint-down(lg) {
                margin-top: 40px;
                margin-left: auto;
                margin-right: auto;
            }
            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .text-power-day {
            font-size: 40px;
            font-family: $NunitoExtraBold;
            color: $brown;
            text-align: left;
            line-height: 1.5em;
            @include media-breakpoint-down(lg) {
                font-size: 40px;
                text-align: center;
            }
            @include media-breakpoint-down(md) {
                font-size: 40px;
                text-align: center;
            }
        }

        .text-16-psn {
            font-size: 33px;
            font-family: $NanumRegular;
            color: $brown;
            text-align: left;
            line-height: 1.5em;
            @include media-breakpoint-down(lg) {
                font-size: 40px;
                line-height: 1em;
                margin-top: 8px;
                text-align: center;
            }
            @include media-breakpoint-down(md) {
                font-size: 40px;
                line-height: 1em;
                margin-top: 8px;
                text-align: center;
            }
        }
    }
}

.button-yellow {
    box-sizing: border-box;
    width: auto;
    margin-top: 35px;
    font-size: 30px !important;
    min-height: 61px !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    color: white;
    font-family: $NanumRegular;
    font-weight: 400;
    background: $yellow;
    border: 3px solid #ffffff;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        min-width: 200px !important;
    }
}
.button-brown-big {
    box-sizing: border-box;
    width: auto;
    margin-top: 35px;
    font-size: 35px !important;
    height: 61px !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    color: white;
    font-family: $NanumRegular;
    font-weight: 400;
    background: $brown;
    border: 3px solid #ffffff;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        min-width: 200px !important;
    }
}

.video-mystery-card {
    width: 400px !important;
    height: 400px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px !important;

    @include media-breakpoint-down(md) {
        width: 250px !important;
        height: 250px !important;
    }

    video {
        width: 300px;
        height: 300px;
        border-radius: 16px !important;
    }
}

.grid-50-control {
    display: grid;
    grid-template-columns: 50% 50%;
}
.text-reveal {
    font-size: 60px;
    margin-top: 45px;
    color: $brown;
    font-family: $NunitoExtraBold;
    text-align: center;

    @include media-breakpoint-down(md) {
        font-size: 45px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 35px;
    }
}

.grounded-radiants {
    width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border: double 4px transparent;
    border-radius: 20px;
    background-image: linear-gradient($bgYellow, $bgYellow),
        radial-gradient(circle at top left, #f7af31, #f75531, #a0f731, #31bbe7, #2450ec, #f515fa, #f7af31);
    background-origin: border-box;
    background-clip: content-box, border-box;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.gradient-control {
    width: 100%;
    position: relative;
    padding: 24px;

    .mint-date-text {
        margin-top: 4px;
        color: $brown;
        font-family: $fontMedium;
        font-size: 36px;

        @include media-breakpoint-down(md) {
            font-size: 29px;
        }
    }
    .mint-price {
        margin-top: 24px;
        color: $brown;
        font-family: $fontBold;
        letter-spacing: 4px;
        font-size: 28px;

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }
    }

    .count-down-control {
        display: flex;
        margin-top: 15px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;

        .text-item {
            display: flex;
            margin-left: 16px;
            margin-right: 16px;
            flex-direction: column;
        }

        .text-date {
            color: $yellow;
            font-size: 45px;
            font-family: $fontBold;
            @include media-breakpoint-down(md) {
                font-size: 30px;
            }
        }

        .text-desc {
            color: $yellow;
            font-size: 22px;
            font-family: $fontBold;
            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
        }
    }
}

.private-sale-img {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;
    object-fit: contain;
}

.sold-out-img {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;
    object-fit: contain;
    margin-top: 16px;
}
.button-brown-whitelist {
    box-sizing: border-box;
    width: 80%;
    margin-top: 16px;
    font-size: 24px !important;
    min-height: 61px !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    color: white;
    font-family: $fontBold;
    font-weight: 400;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    background: $brown;
    border: 3px solid #ffffff;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        width: 80%;
    }
    @include media-breakpoint-down(sm) {
        width: 90%;
        font-size: 20px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

.button-white-whitelist {
    box-sizing: border-box;
    width: 80%;
    margin-top: 16px;
    font-size: 24px !important;
    min-height: 61px !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    color: $brown;
    font-family: $fontBold;
    font-weight: 400;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    background: white;
    border: 3px solid $brown;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        width: 80%;
    }
    @include media-breakpoint-down(sm) {
        width: 90%;
        font-size: 20px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}
.video-sneak-peek {
    margin-top: 15px;
    width: 600px !important;
    height: 600px !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px !important;

    @include media-breakpoint-down(md) {
        width: 250px !important;
        height: 250px !important;
    }

    video {
        width: 600px;
        height: 600px;
        border-radius: 16px !important;
    }
}
.button-connect-wallet {
    box-sizing: border-box;
    width: 60%;
    margin-top: 35px;
    font-size: 30px !important;
    min-height: 61px !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    color: white;
    font-family: $NanumRegular;
    font-weight: 400;
    background: $brown;
    border: 3px solid #ffffff;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        min-width: 200px !important;
        font-size: 20px !important;
        width: 100%;
    }
}
.button-brown {
    box-sizing: border-box;
    width: auto;
    margin-top: 35px;
    font-size: 30px !important;
    min-height: 61px !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    color: white;
    font-family: $NanumRegular;
    font-weight: 400;
    background: $brown;
    border: 3px solid #ffffff;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        min-width: 200px !important;
        font-size: 22px;
    }
}
.section-3-control {
    background: url(../../assets/images/section-3-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;

    @include media-breakpoint-down(lg) {
        margin-top: 0%;
    }

    @include media-breakpoint-down(md) {
        margin-top: 0%;
    }

    .next-event-text {
        margin-top: 60px;
        color: $red;
        font-size: 30px;
        text-align: center;
        font-family: $NunitoExtraBold;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .next-event-desc {
        font-size: 36px;
        color: $brown;
        text-align: center;
        font-family: $NanumRegular;
        line-height: 1em;
        margin-top: 30px;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .grid-control {
        margin-top: 25%;
        display: grid;
        grid-template-columns: 50% 50%;

        @include media-breakpoint-down(xl) {
            margin-top: 35%;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 60%;
        }

        @include media-breakpoint-down(md) {
            margin-top: 35%;
            grid-template-columns: 100%;
        }

        .mystery-card-img {
            width: 60%;
            height: auto;
            object-fit: contain;

            @include media-breakpoint-down(md) {
                width: 80%;
            }
        }

        .text-total {
            font-size: 120px;
            color: $brown;
            font-family: $NunitoExtraBold;
            text-align: left;
            line-height: 107px;
            @include media-breakpoint-down(lg) {
                font-size: 80px;
            }
            @include media-breakpoint-down(md) {
                font-size: 80px;
                text-align: center;
            }
        }

        .text-mystery-box {
            font-size: 70px;
            color: $brown;
            font-family: $NunitoBold;
            text-align: left;
            @include media-breakpoint-down(lg) {
                font-size: 50px;
            }
            @include media-breakpoint-down(md) {
                font-size: 40px;
                text-align: center;
            }
        }

        .text-sale-in {
            color: $red;
            font-size: 40px;
            font-family: $fontBold;
            text-align: left;
            @include media-breakpoint-down(md) {
                font-size: 30px;
                text-align: center;
            }
        }
    }
}
.presale-border-control {
    display: grid;
    grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 40px !important;

    @include media-breakpoint-down(md) {
        width: 100% !important;
        grid-template-columns: 21% 5% 21% 5% 21% 5% 21%;
    }
}

.border-sale {
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-color: white;
    border-width: 0px;
    border-style: solid;
    padding: 4px;
    min-height: 100px;

    @include media-breakpoint-down(md) {
        min-height: 70px;
    }
}

.border-sale-indicator {
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 4px;
    min-height: 100px;

    @include media-breakpoint-down(md) {
        min-height: 70px;
    }
}

.text-days {
    font-size: 35px;
    font-weight: 400;
    font-family: $fontBold;
    color: $brown;
    line-height: 1.6em;
    text-shadow: 2px 2px 0px var(--red);

    @include media-breakpoint-down(lg) {
        font-size: 27px;
    }

    @include media-breakpoint-down(md) {
        font-size: 25px;
        text-align: center;
    }
    @include media-breakpoint-down(sm) {
        font-size: 18px;
        text-align: center;
    }
}

.roadmap-control {
    width: 100%;
    margin-top: 200px;

    .roadmap-header {
        width: 70%;
        height: auto;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .roadmap-grid {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 33% 33% 33%;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 100%;
        }

        .roadmap-border {
            position: relative;
            width: 90%;
            height: fit-content;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 70px;
            padding-bottom: 24px;
            background: #f9f5eb;
            border: 4px solid $brown;
            box-shadow: 8px 8px 0px $brown;
            border-radius: 41px;

            .ui-control {
                margin-left: 16px;
                margin-right: 16px;
            }
            .text-li {
                font-size: 20px;
                color: $brown;
                font-family: $NunitoBold;
                text-align: left;

                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 90px;
            }
        }

        .roadmap-todo-img {
            position: absolute;
            left: 0;
            right: 0;
            top: -40px;
            height: 80px;
            width: 100%;
            object-fit: contain;

            @include media-breakpoint-down(md) {
                top: -30px;
                height: 60px;
            }
        }
    }

    .roadmap-text {
        font-size: 80px;
        font-family: $NunitoExtraBold;
        letter-spacing: 0.16em;
        color: $brown;

        @include media-breakpoint-down(md) {
            font-size: 35px;
        }
    }

    .roadmap-img {
        width: 90%;
        height: auto;
        object-fit: contain;
    }
}

.text-countdown {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    font-weight: 400;
    font-family: $fontBold;
    color: $brown;
    line-height: 1.6em;
    text-shadow: 2px 2px 0px var(--red);

    @include media-breakpoint-down(md) {
        font-size: 45px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 32px;
    }
}

.sub-text-in-button {
    font-size: 17px;
    font-family: $fontRegular;
    color: $brown;
    margin-top: -15px !important;
}

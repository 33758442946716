@import "../index.scss";


.popup-reveal{

    background-color: transparent !important;
    border: none !important;
    box-shadow:  none !important;

    .video-mystery-card {
        width: 600px !important;
        height: 600px !important;
        margin-left: auto;
        margin-right: auto;
        border-radius: 16px !important;

        @include media-breakpoint-down(md) {
            width: 400px !important;
            height: 400px !important;
        }

        @include media-breakpoint-down(sm) {
            width: 250px !important;
            height: 250px !important;
        }

        video {
            width: 300px;
            height: 300px;
            border-radius: 16px !important;
        }
    }

    .button-brown{
        margin-top: 25px;
        width: 300px;
        height: 61px;
        margin-left: auto;
        margin-right: auto;
        color: white;
        font-family: $NanumRegular;
        font-size: 28px;
        background-color: $brown;
        border: 4px solid white;
        box-shadow: 6px 6px 0px $brown;
    }

    



}
@import "../../index.scss";


.footer-control {
    background-color: $dark;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    z-index: 10;
    position: fixed;
        width: 100%;
    grid-template-columns: 50% 50%;

    @include media-breakpoint-down(md){
        grid-template-columns: 100%;
        position: fixed;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}
.button-floating{
    position: fixed;
    min-width: 150px;
    z-index: 9;
    min-height: 61px;
    bottom: 0;
    right: 0;
    font-family: $NanumRegular;
    font-weight: 700;
    font-size: 30px;
    color: white;
    margin-bottom: 50px;
    border-radius: 24px;
    margin-right: 36px;
    background-color: $red;
    border: solid 2px $brown;
    box-shadow: 5px 5px 0px $brown;

    animation: bounce 1s infinite;

    @include media-breakpoint-down(md){
        margin-bottom: 30px;
    }

    &::hover{
        background-color: $red !important;
    }
   
}
.footer-control-black {
    z-index: 10;
    background-color: black;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    position: sticky;
    width: 100%;
    grid-template-columns: 50% 50%;

    @include media-breakpoint-down(md){
        grid-template-columns: 30% 35% 35%;
        padding-top: 30px;
        height: auto;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.footer-bottom-control{
    display: grid;
    width: 70%;
    grid-template-columns: 25% 25% 25% 25%;

    @include media-breakpoint-down(lg){
        grid-template-columns: 50% 50%;
        width: 100%;
    }

    @include media-breakpoint-down(md){
        grid-template-columns: 100%;
    }
}

.footer-text-title{
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    font-family: $fontBold;
    color: $yellow;
    line-height: 1.2em;
    text-decoration: none;

    @include media-breakpoint-down(md){
        font-size: 15px;
        font-family: $fontMedium;
    }
}

.footer-text-title:hover{
   color: $red !important;
}
.footer-text-li-about{
    margin-bottom: 8px;
    text-align: center;
    border-right-style: solid;
    border-right-color: $yellow;

    @include media-breakpoint-down(md){
        border-right-style: none;
        border-right-color: $yellow;
    }
}
.footer-text-li{
    margin-bottom: 8px;
    text-align: center;
    border-right-style: solid;
    border-right-color: $yellow;

    @include media-breakpoint-down(sm){
        font-size: 7px;
    }
    
}

.footer-text-li-last{
    margin-bottom: 8px;
    text-align: center;
    border-right-style: none;
    border-right-color: $yellow;
    @include media-breakpoint-down(sm){
        font-size: 7px;
    }
}

.logo {
    width: 300px;
    height: 100px;
    object-fit: contain;

    @include media-breakpoint-down(md){
        width: 200px;
        height: auto;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }
}

.contact-control {
    justify-content: end !important;
    margin-left: auto !important;
    @include media-breakpoint-down(md){
        justify-content: center !important;
        margin-left: auto !important;
        margin-right: 0px !important;
    }
}

.follow-text {
    font-size: 40px;
    margin-left: auto;
    margin-bottom: 40px;
    font-weight: bold;
    font-family: $fontBold;
    color: $yellow;
    line-height: 1.2em;
    text-shadow: 2px 3px #000;

    @include media-breakpoint-down(md){
        font-size: 16px;
        margin-bottom: 8px;
        margin-left: auto !important;
        margin-right: 0;
        text-align: right;
        font-family: $fontMedium;
    }
}

.contact-item {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    margin-right: 16px;
    object-fit: contain;

    @include media-breakpoint-down(md){
        width: 30px;
        height: 30px;
        margin-left: 8px;
        margin-right: 0px;
    }

}

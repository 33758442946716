@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

$fontBold: "Poppins-Bold";
$fontRegular: "Poppins-Regular";
$fontMedium: "Poppins-Medium";
$fontLight: "Poppins-Light";
$fontItalic: "Poppins-Italic";

$NunitoExtraBold: "Nunito-ExtraBold";
$NunitoBold: "Nunito-Bold";
$NunitoMedium: "Nunito-Medium";
$NunitoLight: "Nunito-Light";
$NunitoSemiBold: "Nunito-SemiBold";
$NunitoRegular: "Nunito-Regular";

$NanumRegular: "NanumPenScript-Regular";
@font-face {
    font-family: $NanumRegular;
    src: local("NanumPenScript-Regular"), url(../public/fonts/NanumPenScript-Regular.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoExtraBold;
    src: local("Nunito-ExtraBold"), url(../public/fonts/Nunito-ExtraBold.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoBold;
    src: local("Nunito-Bold"), url(../public/fonts/Nunito-Bold.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoMedium;
    src: local("Nunito-Medium"), url(../public/fonts/Nunito-Medium.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoLight;
    src: local("Nunito-Light"), url(../public/fonts/Nunito-Light.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoSemiBold;
    src: local("Nunito-SemiBold"), url(../public/fonts/Nunito-SemiBold.ttf) format("truetype");
}
@font-face {
    font-family: $NunitoRegular;
    src: local("Nunito-Regular"), url(../public/fonts/Nunito-Regular.ttf) format("truetype");
}

@font-face {
    font-family: $fontBold;
    src: local("Poppins-Bold"), url(../public/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("Poppins-Medium"), url(../public/fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("Poppins-Regular"), url(../public/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("Poppins-Light"), url(../public/fonts/Poppins-Light.ttf) format("truetype");
}
@font-face {
    font-family: $fontItalic;
    src: local("Poppins-Italic"), url(../public/fonts/Poppins-Italic.ttf) format("truetype");
}

$epicColor: #7362c8;
$rareColor: #3d8ff2;
$legenColor: #ec5662;
$bgMain: #f9f5eb;
$yellow: #f7af31;
$softYellow: #fcefd4;
$bgYellow: #f9f5eb;
$red: #ef4123;
$dark: #211d3e;
$blue: #a6eaff;
$black: #212131;
$brown: #6c4b51;

@keyframes runs {
    0% {
        background-position: 0 0;
    }
    25% {
        background-position: 25vw 0;
    }
    50% {
        background-position: 50vw 0;
    }
    75% {
        background-position: 75vw 0;
    }
    100% {
        background-position: 100vw 0;
    }
}
@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
  
    100% {
        transform: scale(1.5, 1.5);
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        -o-transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
    }
}

.connect-wallet-button {
    box-sizing: border-box;
    width: auto;
    padding: 4px 24px;
    height: 44px;
    font-size: 20px;
    color: white;
    font-family: $NunitoBold;
    background: $red;
    border: 3px solid #ffffff;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        min-width: 200px !important;
    }
}

.font-nanum {
    font-family: $NanumRegular !important;
}

.common-color {
    color: white !important;
}

.epic-color {
    color: $epicColor !important;
}

.legend-color {
    color: $legenColor !important;
}

.rare-color {
    color: $rareColor !important;
}

.high-color {
    color: $legenColor !important;
}

.medium-color {
    color: $yellow !important;
}

.low-color {
    color: $brown !important;
}

.text-left {
    text-align: left !important;
}

.bounce-animation {
    animation: bounce 2s infinite;
}

@import "../index.scss";


.border-control {
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: none !important;
    border-radius: 16px !important;
    border-style: none !important;
    border-width: 0px;
    padding-top: 70px !important;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px !important;
    min-height: 550px !important;

    @include media-breakpoint-down(md){
        min-height: 400px !important;
    }

    .img-popup{
        width: 100%;
        height: auto;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .img-desktop{
        width: 70%;
        height: auto;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }
    
}
.button-ok {
    box-sizing: border-box;
    width: auto;
    margin-top: 35px;
    font-size: 40px !important;
    min-height: 61px !important;
    padding-left: 48px !important;
    padding-right: 48px !important;
    color: white;
    font-family: $NanumRegular;
    font-weight: 400;
    background: $brown;
    border: 3px solid #ffffff;
    box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        min-width: 200px !important;
    }
}
.lottie-control{

    width: 200px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: 50px;
}
.loading-control {
    width: 100%;
    height: auto;
    margin: auto;
    .button-default {
        margin-top: 30px;
        margin-bottom: 30px !important;
        min-width: 310px !important;
        height: 51px;
        font-size: 18px;
        border-style: none;
        font-weight: 700;
        font-family: $fontMedium;
        color: white;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 410px;
        background: var(--red);
        border: 3px solid white;
        box-sizing: border-box;
        box-shadow: 4px 6px 0px white;
        border-radius: 8px;
        animation: bounce 2s infinite;
        @include media-breakpoint-down(md) {
            width: 70% !important;
            font-size: 19px;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        @include media-breakpoint-down(sm) {
            width: 100% !important;
            min-height: auto !important;
            min-width: 0 !important;
            padding: 8px !important;
            height: auto;
        }
    }
    .text-loading {
        color: white;
        font-size: 22px;
        margin-top: 25px;
        font-family: $fontBold;
        text-align: center;
        animation: blink 1s infinite;
    }

    .text-desc {
        color: white;
        font-size: 17px;
        margin-top: 25px;
        font-family: $fontRegular;
        text-align: center;
    }


    @keyframes blink {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @include media-breakpoint-down(md){
        width: 100%;
    }
}

.close-button{
    z-index: 4 !important;
}
.background-control{
    border: none !important;
    box-shadow: none !important;
    background-color: rgba($color: var(--dark), $alpha: 1) !important;
    margin-top: 100px !important;
    width: 30% !important;
  
    @include media-breakpoint-down(lg){
        width: 80% !important;
    }
    @include media-breakpoint-down(md){
        margin-top: 100px !important;
        width: 90% !important;
    }
}


@keyframes bounce {
    0% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        -o-transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
    }
}

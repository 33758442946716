@import "../../index.scss";

.account {
    .container-control {
        padding-left: 0px !important;
        padding-right: 0px !important;
        min-height: 900px !important;
        background-color: $bgYellow;
    }
    .next-event-text {
        margin-top: 30px;
        color: $red;
        font-size: 30px;
        text-align: center;
        font-family: $NunitoExtraBold;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .text-header {
        font-size: 112px;
        font-weight: 400;
        font-family: $NanumRegular;
        color: $brown;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 60px;
        }
    }

    .choice-control {
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 40px;
            flex-direction: column;
        }

        .choice-box-active {
            border: 4px solid $brown;
            background-color: $bgMain;
            min-width: 269px;
            margin-left: 16px;
            margin-right: 16px;
            border-radius: 16px;
            color: $brown;
            font-size: 24px;
            font-family: $fontBold;
            height: 56px;
            box-shadow: 8px 8px 0px $brown;

            @include media-breakpoint-down(md) {
                margin-top: 16px !important;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        .choice-box {
            margin-left: 16px;
            margin-right: 16px;
            border: 4px dashed $brown;
            color: $brown;
            font-size: 24px;
            font-family: $fontBold;
            border-radius: 16px;
            background-color: $bgMain;
            min-width: 269px;
            height: 56px;
            @include media-breakpoint-down(md) {
                margin-top: 45px !important;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    }

    .box-nft-control {
        margin-top: 90px;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        row-gap: 32px;
        @include media-breakpoint-down(xl) {
            grid-template-columns: 33% 33% 33%;
        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: 50% 50%;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }

        .box-nft {
            border: 4px solid $brown;
            background-color: $bgMain;
            width: 90%;
            padding: 24px;
            border-radius: 16px;
            height: auto;
            box-shadow: 8px 8px 0px $brown;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
            }
        }
        .video-mystery-card {
            width: 200px !important;
            height: 200px !important;
            margin-left: auto;
            margin-right: auto;
            border-radius: 16px !important;

            @include media-breakpoint-down(md) {
                width: 180px !important;
                height: 180px !important;
            }

            @include media-breakpoint-down(sm) {
                width: 250px !important;
                height: 250px !important;
            }

            video {
                width: 300px;
                height: 300px;
                border-radius: 16px !important;
            }
        }
        .text-header {
            margin-top: 8px;
            font-size: 22px;
            color: $brown;
            font-family: $fontBold;
            text-align: left;
        }

        .text-collection {
            font-size: 18px;
            color: $yellow;
            font-family: $NanumRegular;
            text-align: left;
        }

        .button-brown {
            margin-top: 25px;
            width: 100%;
            height: 61px;
            color: white;
            font-family: $NanumRegular;
            font-size: 28px;
            background-color: $brown;
            border: 4px solid white;
            box-shadow: 6px 6px 0px $brown;
        }
    }

    .reveal-control {
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 33% 33% 33%;

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
        }
    }
    .text-days {
        font-size: 35px;
        font-weight: 400;
        font-family: $fontBold;
        color: $brown;
        text-align: center;
        line-height: 1.6em;
        text-shadow: 2px 2px 0px var(--red);

        @include media-breakpoint-down(lg) {
            font-size: 27px;
        }

        @include media-breakpoint-down(md) {
            font-size: 25px;
            text-align: center;
        }
        @include media-breakpoint-down(sm) {
            font-size: 18px;
            text-align: center;
        }
    }
    .presale-border-control {
        width: 100%;
        display: grid;
        grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 40px !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
            grid-template-columns: 21% 5% 21% 5% 21% 5% 21%;
        }
    }

    .next-event-desc {
        font-size: 36px;
        color: $brown;
        text-align: center;
        font-family: $NanumRegular;
        line-height: 1em;
        margin-top: 30px;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .own-card-control {
        width: 100%;
        min-height: 600px;
        margin-top: 5%;
        padding-bottom: 15%;

        @include media-breakpoint-down(md) {
            min-height: 600px;
        }
    }

    .own-card-control-item {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;

        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }

        .text-own-card {
            margin-left: 8px;
            margin-right: 8px;
            font-size: 60px;
            color: $brown;
            font-family: $NanumRegular;
            font-weight: 500;

            @include media-breakpoint-down(md) {
                font-size: 35px;
                font-weight: 600;
            }
        }

        .card-img {
            width: 50px;
            height: auto;
            object-fit: contain;
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    .loading-text {
        font-size: 45px;
        color: $brown;
        font-family: $NanumRegular;
        font-weight: 700;
        text-align: center;
        padding-top: 100px;
    }
    .connect-wallet-button-brown {
        box-sizing: border-box;
        width: auto;
        margin-top: 35px;
        font-size: 40px !important;
        min-height: 61px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
        color: white;
        font-family: $NanumRegular;
        font-weight: 400;
        background: $brown;
        border: 3px solid #ffffff;
        box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
        border-radius: 8px;

        @include media-breakpoint-down(md) {
            min-width: 200px !important;
            padding-left: 24px !important;
            padding-right: 24px !important;
            font-size: 22px !important;
        }
    }

    .img-card-control {
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        width: 250px;
        position: relative;
        min-height: 450px;

        .animate {
            transition: none;
            animation: holoCard 12s ease 0s 1;
            &:before {
                transition: none;
                animation: holoGradient 12s ease 0s 1;
            }
            &:after {
                transition: none;
                animation: holoSparkle 12s ease 0s 1;
            }
        }

        @include media-breakpoint-down(md) {
        }
    }

    .loading-nft-control {
        display: flex;
        flex-direction: column;
        height: 300px !important;

        .text-loading {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 34px;
            color: $brown !important;
            text-align: center;
            font-family: "Nunito-Bold";
            animation: bounce 2s infinite;
        }
    }

    .no-nft-control {
        display: flex;
        flex-direction: column;

        .text-no-nft {
            font-size: 28px;
            color: $brown;
            text-align: center;
            font-family: "Nunito-Bold";
        }

        .button-active {
            margin-top: 30px;
            width: 40%;
            padding: 4px 24px;
            background: $yellow;
            margin-left: auto;
            margin-right: auto;
            border: 3px solid $yellow;
            box-sizing: border-box;
            box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.8);
            border-radius: 8px;
            font-family: $NanumRegular;
            color: white;
            font-size: 30px;
        }
    }

    .text-nft-in-card {
        position: absolute;
        margin-top: 268px;
        z-index: 99999;
        color: white;
        font-size: 17px;
        font-weight: bold;
        font-family: "Nunito-Bold";
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md) {
            margin-top: 121%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 92%;
        }
    }

    .img-nft-in-card {
        position: absolute;
        width: 245px;
        margin-top: 40px;
        height: 240px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        border-radius: 16px;
        z-index: 1;
        object-fit: cover;

        @include media-breakpoint-down(md) {
            width: 200px !important;
            height: 200px !important;
        }
    }
}

@import "../../index.scss";

.detail-nft {
    .common-color {
        color: $brown !important;
    }

    .epic-color {
        color: $epicColor !important;
    }

    .legend-color {
        color: $legenColor !important;
    }

    .rare-color {
        color: $rareColor !important;
    }

    .page-control {
        background: url(../../assets/images/detail/bg-detail.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        min-height: 1000px;
        padding-bottom: 50px;
    }

    .grid-control {
        width: 100%;
        margin-top: 100px;
        display: grid;
        grid-template-columns: 40% 60%;

        @include media-breakpoint-down(lg) {
            margin-top: 50px;
            grid-template-columns: 100%;
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }

        .back-control {
            cursor: pointer;
            @include media-breakpoint-down(lg) {
                margin-bottom: 16px;
            }
            .back-arrow {
                width: 12px;
                height: 12px;
                object-fit: contain;

                @include media-breakpoint-down(lg) {
                    width: 18px;
                    height: 18px;
                }
            }

            .back-text {
                font-size: 20px;
                font-family: $NunitoBold;
                color: $brown;

                @include media-breakpoint-down(lg) {
                    font-size: 24px;
                    margin-left: 16px;
                }
            }
        }

        .left-control {
            display: flex;
            flex-direction: column;

            .nft-border {
                width: 425px !important;
                height: 425px !important;
                border-radius: 16px;
                margin-left: auto;
                margin-right: auto;
                border: solid 4px $brown;
                box-shadow: 4px 4px 0px $brown;
                @include media-breakpoint-down(xl) {
                    margin-top: 15%;
                    width: 285px !important;
                    height: 285px !important;
                }

                @include media-breakpoint-down(lg) {
                    margin-top: 0%;
                    width: 355px !important;
                    height: 355px !important;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 0%;
                    width: 285px !important;
                    height: 285px !important;
                }

                video {
                    border-radius: 12px !important;
                    width: 420px !important;
                    height: 420px !important;
                    object-fit: cover;

                    @include media-breakpoint-down(xl) {
                        width: 280px !important;
                        height: 280px !important;
                    }

                    @include media-breakpoint-down(lg) {
                        margin-top: 0%;
                        width: 350px !important;
                        height: 350px !important;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-top: 0%;
                        width: 280px !important;
                        height: 280px !important;
                    }
                }
            }
        }

        .right-control {
            display: flex;
            flex-direction: column;
            margin-left: 24px;

            @include media-breakpoint-down(lg) {
                margin-left: 0px;
            }

            .classify-chart-img {
                width: auto;
                height: 33px;
                object-fit: contain;
                margin-top: 24px;
                margin-right: auto;

                @include media-breakpoint-down(lg){
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .box-control {
                display: flex;
                margin-top: 16px;
                width: 100%;
                height: auto;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                padding-bottom: 24px;
                background-color:$bgMain;
                border-radius: 16px;
                margin-left: auto;
                margin-right: auto;
                border: solid 4px $brown;
                box-shadow: 4px 4px 0px $brown;

                .squad-text-control {
                    flex-direction: row;

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                }
                .text-header {
                    font-size: 24px;
                    font-family: $NunitoExtraBold;
                    color: $brown;
                    text-align: left;

                    @include media-breakpoint-down(sm){
                        font-size: 20px;
                        text-align: center;
                    }
                }

                .text-nanum-header {
                    margin-left: 16px;
                    font-size: 40px;
                    font-family: $NanumRegular;
                    font-weight: 600;
                    color: $brown;
                    text-align: left;

                    @include media-breakpoint-down(md){
                        margin-left: 0px;
                    }

                    @include media-breakpoint-down(sm){
                        font-size: 30px;
                        text-align: center;
                    }
                }

                .text-desc {
                    margin-top: 8px;
                    margin-bottom: 8px;
                    font-size: 17px;
                    color: $brown;
                    text-align: left;
                    font-family: $NunitoMedium;

                    @include media-breakpoint-down(sm){
                        font-size: 15px;
                        text-align: center;
                    }
                }

                .id-control {
                    margin-top: 16px;
                    width: auto;
                    background-color: $yellow;
                    padding: 8px;
                    border-radius: 8px;
                    margin-right: auto;
                    color: white;
                    font-family: $fontBold;
                    font-size: 24px;
                    margin-bottom: 16px;

                    @include media-breakpoint-down(sm){
                        font-size: 20px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .text-yellow-link {
                    color: $yellow;
                    font-family: $NunitoExtraBold;
                    font-size: 22px;
                    font-weight: bold;
                    margin-left: 16px;
                    text-decoration: underline;
                }

                .divider-line {
                    margin-top: 16px;
                    margin-bottom: 16px;
                    width: 100%;
                    height: 2px;
                    border-radius: 16px;
                    background-color: $brown;
                }

                .image-ungear{
                    margin-top: 16px;
                    width: 150px;
                    height: 150px;
                    object-fit: contain;
                    border-radius: 16px;
                    margin-right: 24px;

                    @include media-breakpoint-down(md){
                        width: 100px;
                        height: 100px;
                    }
                }

                .element-control {
                    display: inline-block;
                    margin-top: 8px;
                    margin-bottom: 16px;
                    text-align: left;

                    @include media-breakpoint-down(md){
                   
                        width: 100%;
                        text-align: left;
                    }

                    @include media-breakpoint-down(sm){
                   
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .icon-img {
                    margin-right: 16px;
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                    display: inline;

                    @include media-breakpoint-down(md){
                        display: inline;
                    }

                    @include media-breakpoint-down(sm){
                        display: inline;
                        margin-left: auto;
                        margin-right: auto;
                        width: 36px;
                        height: 36px;
                        margin-bottom: 16px;
                    }
                }

                .text-element {
                    font-size: 20px;
                    font-family: $NunitoExtraBold;
                    margin-left: 4px;
                    margin-right: 4px;

                    @include media-breakpoint-down(md){
                        font-size: 15px;
                    }

                    @include media-breakpoint-down(sm){
                       text-align: center;
                       font-size: 16px;
                       margin-top: 16px !important;
                    }
                }

                .text-name-element {
                    font-size: 20px;
                    font-family: $NunitoBold;
                    color: $brown;
                    margin-left: 4px;
                    margin-right: 4px;
                    @include media-breakpoint-down(md){
                        font-size: 15px;
                    }

                    @include media-breakpoint-down(sm){
                        text-align: center;
                        font-size: 16px;
                     }
                }
            }
        }
    }

    .button-opensea{
        cursor: pointer;
        margin-top: 24px;
        width: auto;
        padding-left: 24px;
        padding-right: 24px;
        height: 61px;
        background-color: #2081E2;
        border-radius: 16px;
        border: 4px solid white;
        box-shadow: 4px 4px 0px rgb(151, 151, 151);

        .img-opensea{
            width: 35px;
            height: 35px;
            object-fit: contain;
        }

        .text-opensea{
            font-size: 24px;
            color:white;
            font-family: $NunitoExtraBold;
            margin-top: auto;
            margin-left: 16px;
            margin-bottom: auto;
        }

        &:hover{
            animation: bounce 1s infinite;
        }
    }

    .text-loading{
        font-size: 45px;
        font-family: $NanumRegular;
        font-weight: 700;
        padding-top: 50px;
        color: $brown;
        padding-bottom: 50px;
        text-align: center;
        animation: blink 2s infinite;
    }
}
